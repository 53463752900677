<template>
  <section class="page__title-area pt-20 pb-30">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div class="page__title-content mb-25">
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/data-tools"
                        >{{ $t("Data Analytics Tools") }}
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre"
                >{{ $t("Data Analytics common Tools used in Industry") }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__tab-conent">
            <div class="tab-content" id="courseTabContent">
              <div
                class="tab-pane fade show active"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">Python</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: Python is a versatile, high-level programming language with a wide range of libraries for data analysis (Pandas), visualization (Matplotlib, Seaborn), and machine learning (scikit-learn, TensorFlow). Its simplicity and readability make it popular among data scientists and analysts."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Data manipulation, statistical analysis, machine learning, data visualization."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">R</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: R is a programming language and software environment designed for statistical computing and graphics. It is highly extensible and offers a vast array of packages for statistical analysis, graphical models, and data visualization."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Statistical analysis, predictive modeling, data visualization, bioinformatics."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div
                      class="course__item white-bg mb-30 fix"
                      style="height: 285px"
                    >
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">Tableau</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: Tableau is a leading data visualization tool that allows users to create interactive and shareable dashboards. It helps in understanding data visually, with the ability to connect to almost any database."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Business intelligence, data visualization, dashboard creation, data sharing."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">Power BI</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: Power BI is a business analytics service by Microsoft. It provides interactive visualizations and business intelligence capabilities with an interface simple enough for end users to create their own reports and dashboards."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Business intelligence, data visualization, reporting, dashboard creation."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div
                      class="course__item white-bg mb-30 fix"
                      style="height: 285px"
                    >
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">
                          SAS (Statistical Analysis System)
                        </h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: SAS is a software suite developed for advanced analytics, multivariate analysis, business intelligence, data management, and predictive analytics. It is widely used in healthcare, business, and research."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Predictive modeling, data mining, statistical analysis, business analytics."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">Apache Spark</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: Apache Spark is a unified analytics engine for large-scale data processing. It provides high-level APIs in Java, Scala, Python, and R, and an optimized engine that supports general computation graphs for data analysis tasks."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Big data processing, machine learning, real-time data processing, data analytics."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div
                      class="course__item white-bg mb-30 fix"
                      style="height: 285px"
                    >
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">Google Analytics</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. It's an essential tool for digital marketing and understanding online behavior."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Website traffic analysis, digital marketing effectiveness, user engagement analysis."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div
                      class="course__item white-bg mb-30 fix"
                      style="height: 310px"
                    >
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">Looker</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "Description: Looker is a business intelligence software and big data analytics platform that helps explore, analyze, and share real-time business analytics easily. It's now part of Google Cloud."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Business intelligence, data exploration, data visualization, reporting."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">SPSS</h3>
                        <div class="course__teacher">
                          <p>
                            •
                            {{
                              $t(
                                "SPSS is a comprehensive and flexible statistical software package used for data management, statistical analysis, and graphical representation of data. Originally developed for the social sciences, SPSS has found widespread use in various fields such as market research, health research, surveys and polling, education, data mining, and more due to its user-friendly interface and extensive functionality."
                              )
                            }}
                          </p>
                          <p>
                            •
                            {{
                              $t(
                                "Use Cases: Data exploration, data visualization, statistical analysis."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MsAnalytics",
  components: {},
};
</script>
