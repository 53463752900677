<template>
  <section class="page__title-area pt-20 pb-90">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div
              class="page__title-content mb-25"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/distance-edu">
                        {{ $t("Distance Education") }}
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">{{
                $t("Distance Education")
              }}</span>
            </div>
            <div class="course__tab-content mb-95">
              <div class="tab-content" id="courseTabContent">
                <div
                  class="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div
                    class="course__description"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  >
                    <div class="row mb-50">
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "Distance education, also known as online or remote learning, has become an integral part of the modern education landscape offering flexible and accessible opportunities for individuals to pursue academic and professional development. This educational approach utilizes technology to bridge the physical gap between students and educational institutions, enabling learning from virtually anywhere."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "One of the key advantages of distance education is flexibility. Students can access course materials, lectures, and assignments at their own pace and convenience. This flexibility is especially beneficial for individuals with work commitments, family responsibilities, or other constraints that may make traditional, on-campus learning challenging. Distance education allows learners to balance their studies with other aspects of their lives, providing an avenue for lifelong learning and continuous skill development."
                            )
                          }}
                        </p>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/UK_Distance_Education/distance-edu-1.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row mb-50">
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/UK_Distance_Education/distance-edu-2.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "Technology plays a central role in distance education, offering a diverse range of tools and platforms to facilitate learning. Virtual classrooms, video lectures, discussion forums, and interactive multimedia materials create an engaging and interactive learning experience. Students can collaborate with peers, communicate with instructors, and access resources online, breaking down geographical barriers and fostering a global learning community."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "Accessibility is another significant advantage of distance education. It opens doors for individuals who may face challenges in attending traditional brick-and-mortar institutions due to geographical remoteness, physical disabilities, or other constraints. Distance learning democratizes education, making it more inclusive and reaching a broader audience with diverse backgrounds and circumstances."
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <p>
                      {{
                        $t(
                          "Moreover, distance education often proves to be a cost-effective option for both students and institutions. Without the need for physical classrooms, campus facilities, and additional infrastructure, educational institutions can optimize resources and reduce costs. Students, in turn, benefit from saving on commuting expenses, accommodation, and other associated costs, making education more affordable and accessible."
                        )
                      }}
                    </p>
                    <p>
                      {{
                        $t(
                          "The variety of programs available through distance education is extensive, ranging from short courses and certifications to undergraduate and postgraduate degrees. This diversity allows individuals to choose programs that align with their career goals, enabling them to specialize and acquire relevant skills in a flexible manner. Additionally, many reputable universities and educational institutions around the world now offer online programs, providing learners with access to high-quality education from renowned institutions without the need to relocate."
                        )
                      }}
                    </p>
                    <p>
                      {{
                        $t(
                          "While distance education offers numerous advantages, it also presents challenges that need to be addressed. Effective time management, self-discipline, and strong communication skills are essential for success in an online learning environment. Institutions must ensure that their online programs maintain high standards of academic rigor and provide robust support systems to help students navigate the virtual learning experience."
                        )
                      }}
                    </p>
                    <p>
                      {{
                        $t(
                          "In conclusion, distance education has transformed the educational landscape, offering a flexible, accessible, and technologically driven approach to learning. As technology continues to evolve, distance education is likely to play an increasingly crucial role in meeting the diverse educational needs of a global and dynamic society."
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DistanceEdu",
  components: {},
};
</script>
