<template>
  <section class="page__title-area pt-20 pb-90">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div
              class="page__title-content mb-25"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/about-uk">
                        {{ $t("About UK") }}
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">{{ $t("About UK") }}</span>
            </div>
            <div class="course__tab-content mb-95">
              <div class="tab-content" id="courseTabContent">
                <div
                  class="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div class="course__description">
                    <div
                      class="row mb-50"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "The United Kingdom, commonly known as the UK, is a sovereign country located off the northwestern coast of mainland Europe. Comprising four constituent countries—England, Scotland, Wales, and Northern Ireland—the UK boasts a rich history, diverse culture, and global influence."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "At the heart of the UK is its capital, London, a vibrant metropolis, and a global financial hub. The city is a melting pot of cultures, offering iconic landmarks such as the Tower of London, Buckingham Palace, and the British Museum. London's diverse neighborhoods, world-class theaters, and thriving arts scene make it a dynamic and cosmopolitan center."
                            )
                          }}
                        </p>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/About-Uk/about-3.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="row mb-50"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/About-Uk/about-2.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "The UK's political system is a constitutional monarchy with a parliamentary democracy. The monarch, currently Queen Elizabeth II, serves as the ceremonial head of state, while the Prime Minister leads the government. The UK Parliament, situated at Westminster in London, plays a central role in the country's governance."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "Education is a cornerstone of the UK's global reputation. The country is home to some of the world's most prestigious universities, including the University of Oxford and the University of Cambridge. These institutions have a rich history and are renowned for their academic excellence, producing Nobel laureates, scientists, and thinkers who have shaped the course of human knowledge."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "Culturally, the UK has made significant contributions to literature, music, and cinema. From the plays of William Shakespeare to the music of The Beatles and the films of Alfred Hitchcock, the UK's cultural impact resonates globally. The West End in London is synonymous with world-class theater productions, while music festivals like Glastonbury attract artists and fans from around the world."
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row mb-50"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "The British countryside offers stunning landscapes, from the rolling hills of the Cotswolds to the rugged beauty of the Scottish Highlands. The Lake District in England, Snowdonia in Wales, and the Giant's Causeway in Northern Ireland showcase the diversity of the UK's natural beauty. Outdoor enthusiasts can enjoy hiking, cycling, and exploring historical sites scattered throughout the country."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "Sports play a vital role in UK culture, with football (soccer), cricket, rugby, and tennis being particularly popular. The Premier League, England's top football division, is globally renowned, attracting a massive international fan base. Wimbledon, one of the oldest and most prestigious tennis tournaments, is held annually in London."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "The UK's healthcare system, the National Health Service (NHS), provides comprehensive medical care to residents. This publicly funded system ensures that healthcare is accessible to all, making it a point of pride for many UK citizens."
                            )
                          }}
                        </p>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/About-Uk/about-1.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                    </div>
                    <p>
                      {{
                        $t(
                          "In conclusion, the UK is a diverse and dynamic country with a rich cultural heritage, world-class education system, and global influence. Whether exploring its historic landmarks, enjoying its cultural offerings, or studying at its renowned universities, the UK offers a multifaceted experience that continues to captivate people from around the globe."
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CourseDetailsArea",
  components: {},
};
</script>
