<template>
  <section class="page__title-area pt-20 pb-90">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div
              class="page__title-content mb-25"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/why-study-uk">
                        {{ $t("Why Study in Uk") }}
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">
                {{ $t("Why study in the United Kingdom?") }}
              </span>
            </div>
            <div
              class="course__tab-content mb-95"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <div class="tab-content" id="courseTabContent">
                <div
                  class="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div class="course__description">
                    <div class="row mb-50">
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "Studying in the United Kingdom (UK) offers a plethora of advantages, making it a highly sought-after destination for international students. From world-renowned universities to cultural diversity and a vibrant lifestyle, the UK provides a unique and enriching educational experience."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "First and foremost, the UK is home to some of the oldest and most prestigious universities globally, such as Oxford, Cambridge, Imperial College London, and others. These institutions consistently rank high in global university rankings, ensuring that students receive a top-notch education. The UK's academic excellence is reflected in its rigorous curriculum, cutting-edge research facilities, and renowned faculty members, making it an ideal environment for intellectual growth."
                            )
                          }}
                        </p>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/Why_Study_Uk/why-study-1.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row mb-50">
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/Why_Study_Uk/why-study-3.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "Moreover, the UK is known for its diverse and inclusive culture. With a rich history and a multicultural society, students can interact with people from various backgrounds. This exposure fosters a global perspective, preparing students to thrive in an interconnected world. Additionally, the cultural diversity extends to the culinary scene, arts, and traditions, providing a unique and immersive experience for international students."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "The English language advantage is another compelling reason to choose the UK for higher education. Studying in an English-speaking country enhances language skills, which is beneficial for both academic and professional endeavors. It also provides students with a competitive edge in the global job market, as English is widely used in international business and research."
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row mb-50">
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "Additionally, the UK's education system encourages a holistic approach to learning. Many programs include practical components, internships, and industry placements, ensuring that students graduate with not only theoretical knowledge but also practical experience. This combination makes graduates highly employable and ready to contribute to their respective fields from day one."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "The UK's emphasis on innovation and research further adds to its appeal. The country has a strong commitment to scientific and technological advancements, with numerous opportunities for students to engage in groundbreaking research. This exposure not only enhances academic knowledge but also equips students with critical thinking and problem-solving skills, preparing them for future challenges."
                            )
                          }}
                        </p>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/Why_Study_Uk/why-study-2.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row mb-50">
                      <div class="col-md-6 col-sm-12">
                        <img
                          src="../../assets/img/Why_Study_Uk/why-study-4.webp"
                          style="width: 100%"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <p>
                          {{
                            $t(
                              "The UK's central location in Europe also facilitates travel and exploration. Students can easily visit neighboring countries, experiencing different cultures and broadening their worldview. The well-connected transportation system allows for convenient travel within the UK and beyond, making it an excellent base for exploring Europe."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "In terms of post-graduation opportunities, the UK provides a favorable environment for career development. Many universities have strong ties with industries, offering networking opportunities and career support services. The UK is also home to a variety of multinational companies, providing graduates with a diverse range of employment options."
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <p>
                      {{
                        $t(
                          "In conclusion, studying in the United Kingdom offers a unique blend of academic excellence, cultural diversity, language advantage, research opportunities, and career prospects. The holistic and enriching experience provided by UK universities prepares students not only for successful careers but also for a globally connected and culturally rich future."
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhyStudyUkArea",
  components: {},
};
</script>
