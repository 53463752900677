<template :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
  <section
    class="hero__area hero__height d-flex align-items-center grey-bg-2 p-relative"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <div class="hero__shape" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <img
        class="hero-1-circle"
        src="../../assets/img/shape/hero/hero-1-circle.webp"
        alt=""
      />
      <img
        class="hero-1-circle-2"
        src="../../assets/img/shape/hero/hero-1-circle-2.webp"
        alt=""
      />
      <img
        class="hero-1-dot-2"
        src="../../assets/img/shape/hero/hero-1-dot-2.webp"
        alt=""
      />
    </div>
    <div class="container" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <div
        class="hero__content-wrapper"
        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      >
        <div class="row align-items-center">
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div
              class="hero__content p-relative z-index-1"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <h3 class="hero__title">
                <span>
                  {{ $t("Secure Distance") }}
                </span>
                <span class="yellow-shape">
                  {{ $t("UK") }}
                  <img
                    src="../../assets/img/shape/yellow-bg.webp"
                    alt="yellow-shape"
                  />
                </span>
                {{ $t("university degree without quitting job.") }}
              </h3>
              <p>
                {{
                  $t(
                    "Studigate connects students in the GCC with distance higher education from UK universities."
                  )
                }}
              </p>
              <router-link to="/discover-more" class="e-btn">
                {{ $t("Discover More") }}
              </router-link>
            </div>
          </div>
          <div
            class="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
          >
            <div class="hero__thumb d-flex p-relative">
              <div class="hero__thumb-shape">
                <img
                  class="hero-1-dot"
                  src="../../assets/img/shape/hero/hero-1-dot.webp"
                  alt=""
                />
                <!-- <img
                  class="hero-1-circle-3"
                  src="../../assets/img/shape/hero/hero-1-circle-3.webp"
                  alt=""
                /> -->
                <img
                  class="hero-1-circle-4"
                  src="../../assets/img/shape/hero/hero-1-circle-4.webp"
                  alt=""
                />
              </div>
              <div class="hero__thumb-big mr-30">
                <img
                  src="../../assets/img/home/home-2.webp"
                  width="100%"
                  height="100%"
                  alt="hero-img"
                />
                <div class="hero__quote hero__quote-animation d-none">
                  <span>
                    {{ $t("Tomorrow is our") }}
                  </span>
                  <h4>
                    {{ $t("“When I Grow Up” Spirit Day!") }}
                  </h4>
                </div>
              </div>
              <div class="hero__thumb-sm mt-50 d-none d-lg-block">
                <img src="../../assets/img/home/home-4.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>

<style scoped>
.hero__height {
  padding: 165px 0 50px 0;
  min-height: 0;
}
</style>
