<template>
  <section class="page__title-area pt-20 pb-90">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt="img"
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div
              class="page__title-content mb-25"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/faq">{{ $t("FAQ") }}</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">{{ $t("FAQ") }}</span>
            </div>
            <div class="course__tab-content mt-50">
              <!-- Start Collapse -->
              <div id="accordAPp">
                <ul>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{ $t("What types of programs do you offer?") }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "We offer a wide range of programs including postgraduate, doctoral, and professional development courses across various fields such as business, and IT with more focus on AI and Data Analytics. Our programs are designed to meet the needs of both full-time students and working professionals looking for career advancement."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{ $t("Are your degrees recognized internationally?") }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Yes, all our degrees are awarded by accredited universities and are recognized internationally. We collaborate with universities that have a strong reputation for academic excellence and global recognition."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{ $t("How do distance learning classes work?") }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Distance learning classes are delivered through a state-of-the-art learning management system (LMS) where students can access lectures, course materials, and interact with faculty and peers. Classes may include a mix of live sessions, recorded lectures, discussion forums,and interactive assignments to ensure a comprehensive learning experience."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{ $t("Can I study at my own pace?") }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Many of our programs are designed with flexibility in mind, allowing students to study at their own pace.However, some courses may have scheduled sessions and deadlines to ensure that students stay on track and complete their program in a timely manner."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{
                        $t(
                          "What kind of support services do you offer to distance learning students?"
                        )
                      }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "We provide a range of support services to ensure our distance learning students have a successful learning experience. This includes academic advising, technical support, library access, career services, and wellness resources. Our dedicated team is available to assist students with any challenges they may encounter."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{
                        $t(
                          "What are the admission requirements for your programs?"
                        )
                      }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Admission requirements vary by program but generally include a completed application form, transcripts from previous institutions proof of language proficiency (for non-native English speakers), letters of recommendation, and a personal statement. Some programs may also require specific qualifications or work experience."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{ $t("How do I apply?") }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Prospective students can apply through our online application portal. The process involves submitting your personal details educational background, supporting documents, and any additional information required for your chosen program."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{
                        $t(
                          "Are there any scholarships or financial aid options available?"
                        )
                      }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Yes, a variety of scholarships and financial aid options for eligible students are available in some of the universities we deal with. These are designed to help reduce the financial burden of higher education. Students are encouraged to apply early, as funds are limited and distributed on a competitive basis."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{
                        $t(
                          "How are exams administered for distance learning courses?"
                        )
                      }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Exams for distance education courses may be administered virtually through our secure online proctoring system, which ensures the integrity of the examination process. Some courses may require students to attend in-person exams at authorized centers."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{
                        $t(
                          "How can I choose the right university and course in the UK?"
                        )
                      }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Our consultants are experts in the UK higher education sector and will provide personalized advice based on your academi background, career goals, and preferences. We consider factors such as university rankings, course content, faculty expertise, and career outcomes to recommend options that best suit your needs."
                        )
                      }}
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" checked />
                    <i></i>
                    <h3
                      style="
                        font-size: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                      "
                    >
                      {{
                        $t("What is the cost of studying and living in the UK?")
                      }}
                    </h3>
                    <p
                      style="
                        font-size: 16px;
                        line-height: 2;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        $t(
                          "Costs vary depending on your chosen institution, type of learning, course, and lifestyle. Tuition fees for international students can range widely, and you should also budget for living expenses, including accommodation, food, transportation, and personal expenses. We can provide detailed cost estimates and help you plan your finances."
                        )
                      }}
                    </p>
                  </li>
                </ul>
              </div>
              <!-- End Collapse -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
#accordAPp .transition,
#accordAPp p,
#accordAPp ul li i:before,
#accordAPp ul li i:after {
  transition: all 0.25s ease-in-out;
}

#accordAPp .flipIn,
#accordAPp ul li {
  animation: flipdown 1s ease-in-out;
}

#accordAPp .no-select,
#accordAPp h2 {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#accordAPp {
  min-height: 0;
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  box-shadow: 1px 1px 7px #7c7c7c;
}
@media (max-width: 550px) {
  #accordAPp {
    box-sizing: border-box;
    transform: translate(0, 0);
    max-width: 100%;
    min-height: 100%;
  }
}

#accordAPp h2 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  display: block;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
}
#accordAPp h2 span {
  font-size: 14px;
  background: #494949;
  color: #fff;
  padding: 10px;
  margin-right: 10px;
}
#accordAPp p {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  max-height: 800px;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 20px;
  z-index: 2;
}

#accordAPp ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}
#accordAPp ul li {
  position: relative;
  padding: 15px 20px;
  margin: 0;
  border-top: 1px dotted #dce7eb;
}
#accordAPp ul li:nth-of-type(n) {
  animation-delay: 0.5s;
}
#accordAPp ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 16px;
  right: 30px;
}
#accordAPp ul li i:before,
#accordAPp ul li i:after {
  content: "";
  position: absolute;
  background-color: #2b4eff;
  width: 3px;
  height: 9px;
}
#accordAPp ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}
#accordAPp ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}
#accordAPp ul li input[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
#accordAPp ul li input[type="checkbox"]:checked ~ p {
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}
#accordAPp ul li input[type="checkbox"]:checked ~ i:before {
  transform: translate(2px, 0) rotate(45deg);
}
#accordAPp ul li input[type="checkbox"]:checked ~ i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
</style>
