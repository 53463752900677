<template>
  <section class="page__title-area pt-20 pb-30">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div class="page__title-content mb-25">
              <div
                class="page__title-breadcrumb"
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              >
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/admission-req">
                        {{ $t("Admission Requirements") }}
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">
                {{ $t("MS-Master Degree Admission Requirement in the UK") }}
              </span>
            </div>
            <div
              class="course__tab-content"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <div class="tab-content" id="courseTabContent">
                <div
                  class="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div class="course__description">
                    <p>
                      {{
                        $t(
                          "Admission requirements for a Master of Science (M.S.) program in the UK can vary across different universities and specific courses. However, there are general criteria that most institutions consider when evaluating applicants. Here's an overview of typical requirements for M.S. admission in the UK:"
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AdmissionReq",
  components: {},
};
</script>
