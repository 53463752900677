<template>
  <section class="page__title-area pt-20 pb-30">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div class="page__title-content mb-25">
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/phd">
                        {{ $t("Discover More") }}</router-link
                      >
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">{{ $t("Discover More") }}</span>
            </div>
          </div>
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__tab-conent">
            <div class="tab-content" id="courseTabContent">
              <div
                class="tab-pane fade show active"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <div class="course__teacher">
                          <p>
                            {{
                              $t(
                                "Elevate your professional trajectory by securing a UK-based postgraduate degree in Data Analytics or Business or MBA or a Doctorate Degree, all achievable online without the necessity of forfeiting your current job, income, or the comfort of your home. Engage in a bespoke, individualized consultation with a StudiGrid Higher Education Consultant who is dedicated to understanding your unique career aspirations and educational background. We provide personalized session aims to meticulously analyze and determine the academic or professional degree program that aligns perfectly with your professional goals and personal circumstances."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "Embarking on this educational journey offers you the flexibility to balance your professional commitments with your academic ambitions. The diverse range of programs available ensures that regardless of your field or career stage, there is a qualification that can propel you to the next level. Whether you're looking to gain advanced expertise in your current field, pivot to a new industry, or enhance your leadership and strategic thinking skills, the right degree can open doors to new opportunities and elevate your career to new heights."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "Moreover, earning a degree from a UK institution, renowned globally for its academic excellence and rigorous standards, can significantly enhance your resume and expand your professional network. It provides you with a global perspective, critical in today's interconnected world, and prepares you to tackle complex challenges in your field. The online format of these programs ensures that geographical boundaries do not limit your access to quality education, allowing you to interact with peers and faculty from around the globe, further enriching your learning experience."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "In addition, StidiGate Higher Education is more than just an educational consultancy; it's a strategic planning organization tailored to map out your educational pathway. We provide consultation takes into consideration your current professional standing, your long-term career objectives, and the specific skills and knowledge you need to achieve those goals. It's an opportunity to ask questions, express concerns, and get clarity on the commitments required to successfully complete your chosen program."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "In summary, pursuing an online degree from the UK offers a unique blend of flexibility, global recognition, and personalized support, designed to fit seamlessly into your busy life. It's an investment in your future, providing you with the tools, knowledge, and credentials to advance your career and achieve your professional aspirations."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DiscoverMore",
  components: {},
};
</script>
