<template>
  <section
    class="page__title-area page__title-height page__title-overlay d-flex align-items-center"
    :style="{
      background:
        'url(' + require('../../assets/img/page-title/page-title-3.webp') + ')',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-xxl-12" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
          <div class="page__title-wrapper mt-110">
            <h3 class="page__title">{{ title }}</h3>
            <nav aria-label="breadcrumb" class="mt-20">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" style="margin-left: 10px">
                  <router-link to="/">{{ $t("Home") }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ subtitle }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: String,
    subtitle: String,
  },
};
</script>
